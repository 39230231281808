<template>
  <div class="notices">
    <!-- <div class="titles">
      <i
        class="el-icon-arrow-left"
        style="font-size: 18px;cursor:pointer;"
        @click="goBack"
      ></i>
      公告
    </div> -->
    <!-- <div style="background: #F2F3F8;height: 20px;width:100%">

    </div> -->
    <el-row :gutter="20">
      <el-col>
        <!-- <el-card> -->
        <noticeItem @itemsClick="showPopup"></noticeItem>
        <!-- </el-card> -->
      </el-col>
    </el-row>
    <!-- 
    <el-dialog
    :title="newsDetails.title"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose">
    <p class="newsTitle">{{ newsDetails.title }}</p>
    <div class="newsContent" v-html="newsDetails.content"></div>
  </el-dialog> -->
  </div>
</template>

<script>
import noticeItem from "./noticeItem";
export default {
  components: {
    noticeItem
  },
  data() {
    return {
      dataList: window.a.application,
      dialogVisible: false,
      newsDetails: {
        title: null,
        titleImg: null,
        subtitle: null,
        source: null,
        content: null
      }
    };
  },
  created() {
    // this.getDate()
    this.leaveIframe();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    showPopup(data) {
      (this.dialogVisible = true), (this.newsDetails = data);
    },
    getDate() {
      var arr = [];
      window.a.application.forEach(element => {
        arr.push(element.notice);
      });
      // protocol.param_noticeListMarket.param.columnIdList = arr
      http.getRes(protocol.param_noticeListMarket).then(response => {
        var { message, code, value } = response.data;
        if (Number(code) === 0) {
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
/deep/ .wscnph {
  width: 100%;
}
.notices {
  // border: 1px solid #e6ecf2;
  width: 1200px;
  margin: 0 auto;
  // padding: 10px;
  background-color: #fff;
  // height: 100%;
  margin-top: 20px;
  border-radius: 8px;
}
.newsContent {
  overflow: hidden;
}
.newsDetails .newsTitle {
  font-size: 18px;
  line-height: 45px;
  font-weight: 700;
  text-align: center;
}
.newsDetails .newsImage {
  width: 600px;
  margin: 10px auto;
}
.newsDetails .newsImage img {
  width: 100%;
}
.titles {
  padding: 15px;
  font-size: 16px;
  background-color: #f2f6fa;
}
</style>
