<template>
  <div class="noticeItem">
    <div style="padding: 15px">
      <div
        class="items"
        v-for="(item, index) in dataList"
        :key="index"
        @click="itemsClick(item)"
      >
        <div class="title">{{ item.title }}</div>
        <div class="time">{{ item.createTime | DateFormate }}</div>
      </div>
      <div class="pagination" v-if="dataList.length != 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.current"
          :page-size="pages.pageSize"
          layout="total,  prev, pager, next, sizes"
          :total="pages.totalPage"
        ></el-pagination>
      </div>
      <div v-if="dataList.length == 0" class="nullData">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Object,
  },
  data() {
    return {
      dataList: [
        // {
        //     cmsTypeId: 2,
        //     columnId: 167,
        //     createTime: 1605776788000,
        //     firmId: 3,
        //     id: 453,
        //     sequence: 0,
        //     source: null,
        //     subtitle: null,
        //     title: "商品上市通知",
        //     titleImg: null,
        //     type: 1,
        //     updateTime: 1608886069000,
        //     userId: 0
        // }
      ],
      pages: {
        pageSize: 10,
        current: 1,
        totalPage: null,
      },
    };
  },
  mounted() {
    var that = this;
    setTimeout(() => {
      that.getData();
    });
  },
  methods: {
    itemsClick(item) {
      localStorage.setItem("noticeId", item.id);
      this.$router.push({
        name: "noticeDetailss",
        params: {
          id: item.id,
        },
      });
      // protocolFwd.param_getContentDetails.param.id = item.id;
      // http.getRes(protocolFwd.param_getContentDetails).then(response => {
      //     const { code, message, value } = response.data;
      //     if (code == 0) {
      //         // this.newsDetails = value;
      //         // this.$emit('itemsClick', value)
      //     } else {
      //         this.$EL_MESSAGE(message);
      //     }
      // });
    },
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pages.current = val;
      this.getData();
    },
    getData() {
      // protocol.param_noticeListMarket.param.columnIdList = [window.c.notice];
      // a.param.sort = [{
      //     direction: 'DESC',
      //     property: 'occurTime'
      // }]
      protocol.param_noticeListMarket.param.page = this.pages.current - 1;
      protocol.param_noticeListMarket.param.size = this.pages.pageSize;
      http.getRes(protocol.param_noticeListMarket).then((response) => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          this.dataList = value.content;
          this.pages.totalPage = value.totalElements;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.noticeItem {
  // height: 380px;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 20px;
  background-color: #fff;
  height: 100%;
  border-radius: 8px;
  .titles {
    font-size: 20px;
    font-weight: 700;
  }
  .items {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F1F5F8;
    margin-bottom: 10px;
    padding: 15px 0;
    .time {
      margin-right: 15px;
    }
  }
}
.title1 {
  background-color: #f2f6fa;
  border-bottom: 1px solid #e6ecf2;
  padding: 10px;
}
.nullData {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
}
</style>